import React, { useEffect, useState, useCallback } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import debounce from 'lodash/debounce';

import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { numberAtLeast } from '../../util/validators';
import { PURCHASE_PROCESS_NAME } from '../../transactions/transaction';

import {
  Form,
  FieldTextInput,
  PrimaryButton,
  H6,
} from '../../components';

import EstimatedCustomerBreakdownMaybe from './EstimatedCustomerBreakdownMaybe';

import css from './OrderPanel.module.css';

const handleFetchLineItems = ({
  quantity,
  deliveryMethod,
  displayDeliveryMethod,
  listingId,
  isOwnListing,
  fetchLineItemsInProgress,
  onFetchTransactionLineItems,
}) => {
  const stockReservationQuantity = Number.parseInt(quantity, 10);
  const deliveryMethodMaybe = deliveryMethod ? { deliveryMethod } : {};
  const isBrowser = typeof window !== 'undefined';
  if (
    isBrowser &&
    stockReservationQuantity &&
    (!displayDeliveryMethod || deliveryMethod) &&
    !fetchLineItemsInProgress
  ) {
    onFetchTransactionLineItems({
      orderData: { stockReservationQuantity, ...deliveryMethodMaybe },
      listingId,
      isOwnListing,
    });
  }
};

const renderForm = formRenderProps => {
  const [mounted, setMounted] = useState(false);
  const {
    handleSubmit,
    form: formApi,
    intl,
    formId,
    displayDeliveryMethod,
    listingId,
    isOwnListing,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    price,
    marketplaceName,
    values,
  } = formRenderProps;

  useEffect(() => {
    setMounted(true);

    const { quantity, deliveryMethod } = values;
    if (quantity && !formRenderProps.hasMultipleDeliveryMethods) {
      handleFetchLineItems({
        quantity,
        deliveryMethod,
        displayDeliveryMethod,
        listingId,
        isOwnListing,
        fetchLineItemsInProgress,
        onFetchTransactionLineItems,
      });
    }
  }, []);

  const handleOnChange = useCallback(
    debounce(formValues => {
      const { quantity, deliveryMethod } = formValues.values;
      console.log('formValues', formValues);
      if (mounted) {
        handleFetchLineItems({
          quantity,
          deliveryMethod,
          listingId,
          isOwnListing,
          fetchLineItemsInProgress,
          onFetchTransactionLineItems,
        });
      }
    }, 300),
    [mounted, listingId, isOwnListing, fetchLineItemsInProgress, onFetchTransactionLineItems]
  );

  const handleFormSubmit = e => {
    const { quantity, deliveryMethod } = values || {};
    if (!quantity || quantity < 1) {
      e.preventDefault();
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else if (displayDeliveryMethod && !deliveryMethod) {
      e.preventDefault();
      formApi.blur('deliveryMethod');
      formApi.focus('deliveryMethod');
    } else {
      handleSubmit(e);
    }
  };

  const showBreakdown =
    lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

  const quantityRequiredMsg = intl.formatMessage({ id: 'EstimationForm.quantityRequired' });

  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = submitInProgress || !values?.quantity;

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
      <FieldTextInput
        id={`${formId}.quantity`}
        className={css.quantityField}
        name="quantity"
        type="number"
        min={1}
        label={intl.formatMessage({ id: 'TransactionPanel.hourEstimate' })}
        placeholder={intl.formatMessage({ id: 'TransactionPanel.hourEstimatePlaceholder' })}
        validate={numberAtLeast(quantityRequiredMsg, 1)}
      />

      {showBreakdown ? (
        <div className={css.breakdownWrapper}>
          <H6 as="h3" className={css.bookingBreakdownTitle}>
            <FormattedMessage id="EstimationForm.breakdownTitle" />
          </H6>
          <hr className={css.totalDivider} />
          <EstimatedCustomerBreakdownMaybe
            breakdownData={lineItems}
            lineItems={lineItems}
            currency={price.currency}
            marketplaceName={marketplaceName}
            processName={PURCHASE_PROCESS_NAME}
          />
        </div>
      ) : null}

      <div className={css.submitButton}>
        <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
          <FormattedMessage id="EstimationForm.ctaButton" />
        </PrimaryButton>
      </div>
    </Form>
  );
};

const EstimationForm = props => {
  const intl = useIntl();
  const {
    price,
    currentStock,
    pickupEnabled,
    shippingEnabled,
    displayDeliveryMethod,
    allowOrdersOfMultipleItems,
  } = props;

  if (displayDeliveryMethod && !pickupEnabled && !shippingEnabled) {
    return (
      <p className={css.error}>
        <FormattedMessage id="EstimationForm.noDeliveryMethodSet" />
      </p>
    );
  }

  const hasOneItemLeft = currentStock && currentStock === 1;
  const hasOneItemMode = !allowOrdersOfMultipleItems && currentStock > 0;
  const quantityMaybe = hasOneItemLeft || hasOneItemMode ? { quantity: '1' } : {};
  const deliveryMethodMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
        ? { deliveryMethod: 'pickup' }
        : !shippingEnabled && !pickupEnabled
          ? { deliveryMethod: 'none' }
          : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = { ...quantityMaybe, ...deliveryMethodMaybe };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      displayDeliveryMethod={displayDeliveryMethod}
      {...props}
      intl={intl}
      render={renderForm}
    />
  );
};

EstimationForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  pickupEnabled: false,
  shippingEnabled: false,
  displayDeliveryMethod: false,
  lineItems: null,
  fetchLineItemsError: null,
};

EstimationForm.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,

  formId: string.isRequired,
  onSubmit: func.isRequired,

  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,
  pickupEnabled: bool,
  shippingEnabled: bool,
  displayDeliveryMethod: bool,

  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  onContactUser: func,
};

export default EstimationForm;
import { composeValidators, dateIsAfterToday, required } from '../util/validators';

const MAX_PRICE = 500;

export const getServices = (intl = null) => {
  const services = [
    { option: 'staffing', label: 'Staffing' },
    { option: 'training', label: 'Training' },
    { option: 'recruitment', label: 'Reclutamiento' },
    { option: 'tasks', label: 'Tasks' },
  ];

  return services;
}

export const getServiceDetails = (service, intl = null) => {
  const details = [
    {
      service: 'staffing',
      sections: [
        {
          subtitle: '',
          questions: [
            {
              type: 'paragraph',
              hideOnView: true,
              label: '¿Necesitas desarrolladores para tu equipo? Contrata Consultores o Devs a largo plazo con Futura Talents.\nProfesionales de Salesforce certificados y pre-seleccionados que puedes contratar en menos de 10 días.',
            },
            {
              type: 'checkbox',
              id: 'notifications',
              name: 'notifications',
              hideOnView: true,
              label: 'Deseo que me contacten para saber más'
            },
          ]
        },
        {
          subtitle: '',
          questions: [
            {
              type: 'text',
              name: 'title',
              label: 'Título o nombre de la solicitud',
              placeholder: 'Ej."Consultoría en Salesforce Marketing Cloud"',
              validate: required('Debes asignar un nombre'),
              otherProps: {
                type: 'text',
              }
            },
            {
              type: 'radio',
              name: 'role',
              listingName: 'pub_rol',
              label: '¿Cuál es el rol que requieres?',
              validate: required('Debes seleccionar un rol'),
              options: [
                { value: 'developer', label: 'Developer' },
                { value: 'business-analyst', label: 'Business Analyst' },
                { value: 'solution-architect', label: 'Solution Architect' },
                { value: 'technical-architect', label: 'Techincal Architect' },
                { value: 'consultant', label: 'Consultant' },
                { value: 'project-manager', label: 'Project Manager' },
                { value: 'sales-representative', label: 'Sales Representative' },
                { value: 'presales', label: 'Presales' },
              ]
            },
            {
              type: 'radio',
              name: 'stage',
              label: '¿En qué etapa de desarrollo te encuentras?',
              validate: required('Debes seleccionar una etapa'),
              options: [
                { value: 'dessign', label: 'Tengo el diseño' },
                { value: 'specifications', label: 'Tengo las especificaciones' },
                { value: 'idea', label: 'Tengo la idea' },
                { value: 'NA', label: 'N/A' },
              ]
            },
            {
              type: 'textarea',
              name: 'description',
              label: 'Descripción detallada de la necesidad o proyecto',
              placeholder: 'Describe tus requerimientos',
              validate: required('Debes describir tus requerimientos'),
              otherProps: {
                type: 'textarea',
              }
            },
            {
              type: 'textarea',
              name: 'targets',
              label: 'Objetivos esperados de la colaboración',
              placeholder: 'Describe tus objetivos esperados',
              validate: required('Debes describir tus objetivos'),
              otherProps: {
                type: 'textarea',
              }
            },
            {
              type: 'radio',
              name: 'englishLevel',
              listingName: 'pub_englishProficiency',
              label: 'Nivel de Inglés requerido',
              validate: required('Debes seleccionar una nivel de inglés'),
              options: [
                { value: 'advanced', label: 'Muy bueno' },
                { value: 'intermediate', label: 'Bueno' },
                { value: 'basic', label: 'Básico' },
                { value: 'none', label: 'No requiere manejo de inglés' },
              ]
            },
          ]
        },
        {
          subtitle: 'Detalles del Proyecto',
          questions: [
            {
              type: 'text',
              name: 'duration',
              label: 'Duración estimada',
              placeholder: 'Duración estimada (Ej. 240 horas, 4 semanas)',
              validate: required('Debes ingresar la duración estimada'),
              otherProps: {
                type: 'text',
              }
            },
            {
              type: 'radio',
              name: 'modality',
              label: 'Modalidad de trabajo',
              validate: required('Debes seleccionar una modalidad'),
              options: [
                { value: 'in-person', label: 'Presencial' },
                { value: 'remote', label: 'Remoto' },
                { value: 'hybrid', label: 'Híbrido' },
              ]
            },
            {
              type: 'date',
              name: 'startDate',
              label: 'Fecha estimada de inicio',
              validate: composeValidators(required('Debes seleccionar una fecha de inicio'), dateIsAfterToday('La fecha de inicio debe ser posterior a la fecha actual')),
              placeholder: 'Fecha de inicio',
              otherProps: {
                type: 'date',
                // placeholderText: 'Fecha de inicio',
                // isOutsideRange: () => false
              },
            },
            {
              type: 'date',
              name: 'endDate',
              label: 'Fecha estimada de término',
              validate: composeValidators(required('Debes seleccionar una fecha de término'), dateIsAfterToday('La fecha de término debe ser posterior a la fecha de inicio')),
              placeholder: 'Fecha de término',
              otherProps: {
                type: 'date',
                // placeholderText: 'Fecha de término',
                // isOutsideRange: () => false
              },
            },
            {
              type: 'text',
              name: 'fee',
              listingName: 'price',
              listingRange: [0, MAX_PRICE],
              label: 'Tarifa estimada (USD)',
              placeholder: 'Ingresa la tarifa estimada',
              validate: required('Debes ingresar la tarifa estimada'),
              otherProps: {
                type: 'text',
              }
            },
          ]
        },
        {
          subtitle: 'Condiciones de Trabajo',
          questions: [
            {
              type: 'radio',
              name: 'typeOfContract',
              label: 'Tipo de contratación',
              validate: required('Debes seleccionar un tipo de contratación'),
              options: [
                { value: 'full-time', label: 'Tiempo Completo' },
                { value: 'half-time', label: 'Medio Tiempo' },
                { value: 'hours', label: 'Por Horas' },
              ]
            },
            {
              type: 'radio',
              name: 'shifts',
              label: 'Horarios de Trabajo',
              validate: required('Debes seleccionar un tipo de contratación'),
              options: [
                { value: 'morning', label: 'Mañana' },
                { value: 'afternoon', label: 'Tarde' },
                { value: 'availability', label: 'Según disponibilidad' },
              ]
            },
          ]
        }
      ]
    },
    {
      service: 'training',
      sections: [
        {
          subtitle: '',
          questions: [
            {
              type: 'text',
              name: 'description',
              label: 'Descripción del training',
              placeholder: 'Necesito una capacitación de Salesforce Admin nivel Principiante para mi empresa...',
              validate: required('Debes agregar una descripción'),
              otherProps: {
                type: 'text',
              }
            },
          ]
        },
        {
          subtitle: '',
          questions: [
            {
              type: 'text',
              name: 'participants',
              label: 'Número de participantes',
              placeholder: 'Agrega el numero de participantes',
              validate: required('Debes asignar un número de participantes'),
              otherProps: {
                type: 'text',
              }
            },
            {
              type: 'radio',
              name: 'modality',
              label: 'Modalidad',
              validate: required('Debes seleccionar una modalidad'),
              options: [
                { value: 'in-person', label: 'Presencial (se deberá de pagar viáticos para el consultor)' },
                { value: 'remote', label: 'Remoto' }
              ]
            },
            {
              type: 'location',
              name: 'requestLocation',
              label: 'Ciudad y País',
              validate: required('Debes ingresar tu ciudad y país'),
              visibleParam: 'modality',
              visible: (param) => ['in-person'].includes(param),
            },
            {
              type: 'text',
              name: 'duration',
              label: 'Duración estimada',
              placeholder: 'Duración estimada (Ej. 240 horas, 4 semanas)',
              validate: required('Debes ingresar la duración estimada'),
              otherProps: {
                type: 'text',
              }
            },
            {
              type: 'text',
              name: 'budget',
              listingName: 'price',
              listingRange: [0, MAX_PRICE],
              label: 'Presupuesto estimado (USD)',
              placeholder: 'Ingresa el presupuesto estimado',
              validate: required('Debes ingresar un presupuesto'),
              otherProps: {
                type: 'text',
              }
            },
          ]
        }
      ]
    },
    {
      service: 'recruitment',
      sections: [
        {
          subtitle: '',
          questions: [
            {
              type: 'text',
              name: 'name',
              label: 'Nombre de la vacante',
              placeholder: '(ej. Business Analyst, Developer, Project Manager)',
              validate: required('Debes agregar el nombre de la vacante'),
              otherProps: {
                type: 'text',
              }
            }
          ]
        },
        {
          subtitle: 'General',
          questions: [
            {
              type: 'radio',
              name: 'experienceLevel',
              label: 'Nivel de experiencia',
              validate: required('Debes seleccionar un nivel de experiencia'),
              options: [
                { value: 'senior', label: 'Senior' },
                { value: 'mid', label: 'Mid-Level' },
                { value: 'jr', label: 'Junior' },
              ]
            },
            {
              type: 'radio',
              name: 'englishLevel',
              listingName: 'pub_englishProficiency',
              label: 'Nivel de Inglés requerido',
              validate: required('Debes seleccionar una nivel de inglés'),
              options: [
                { value: 'advanced', label: 'Muy bueno' },
                { value: 'intermediate', label: 'Bueno' },
                { value: 'basic', label: 'Básico' },
                { value: 'none', label: 'No requiere manejo de inglés' },
              ]
            },
            {
              type: 'textarea',
              name: 'functionsDescription',
              label: 'Descripción de funciones',
              placeholder: 'Responsabilidades principales del rol',
              validate: required('Debes describir las funciones'),
              otherProps: {
                type: 'textarea',
              }
            },
          ]
        },
        {
          subtitle: 'Habilidades',
          questions: [
            {
              type: 'textarea',
              name: 'skills',
              label: 'Habilidades técnicas necesarias',
              placeholder: 'Lenguajes de programación,módulos de Salesforce específicos (Marketing Cloud, Sales Cloud), u otras herramientas',
              validate: required('Debes describir las habilidades técnicas necesarias'),
              otherProps: {
                type: 'textarea',
              }
            },
            {
              type: 'textarea',
              name: 'certifications',
              label: 'Certificaciones de Salesforce requeridas o deseadas',
              placeholder: 'Certificaciones requeridas o deseadas',
              validate: required('Debes describir las certificaciones necesarias'),
              otherProps: {
                type: 'textarea',
              }
            },
            {
              type: 'text',
              name: 'experienceAges',
              listingName: 'pub_experienceYears',
              listingRange: [0, 15],
              label: 'Años de experiencia en Salesforce u otras plataformas relacionadas',
              placeholder: 'Ingresa la experiencia en años',
              validate: required('Debes ingresar la experiencia en años'),
              otherProps: {
                type: 'text',
              }
            },
          ]
        }
      ]
    },
    {
      service: 'tasks',
      required: true,
      sections: [
        {
          subtitle: '',
          questions: [
            {
              type: 'checkboxGroup',
              name: 'tasks',
              label: '',
              options: [
                { key: '8-horas-1', label: 'Revisión y optimización de permisos de usuario y roles: Asegurar que las configuraciones de seguridad estén alineadas con las políticas del cliente.' },
                { key: '8-horas-2', label: 'Creación de reportes personalizados: Desarrollo de hasta 5 reportes y dashboards clave para el negocio.' },
                { key: '8-horas-3', label: 'Configuración de colas y asignaciones automáticas: Optimización del flujo de trabajo básico.' },
                { key: '8-horas-4', label: 'Activación de notificaciones de email automáticas: Crear reglas de notificación para leads o casos entrantes.' },
                { key: '8-horas-5', label: 'Configuración inicial de casos y colas de soporte: Para clientes que empiezan con Service Cloud.' },

                { key: '20-horas-1', label: 'Migración de datos básicos: Importación de datos de contactos, cuentas y oportunidades utilizando herramientas de importación de Salesforce.' },
                { key: '20-horas-2', label: 'Automatización básica con Flows: Creación de hasta 3 flujos automatizados que optimicen tareas comunes como la actualización de campos o la asignación de leads.' },
                { key: '20-horas-3', label: 'Configuración inicial de campañas y marketing básico: Implementación de campañas en Salesforce para marketing basado en listas de contactos existentes.' },
                { key: '20-horas-4', label: 'Ajustes rápidos en Lightning App Builder: Personalización básica de páginas.' },

                { key: '40-horas-1', label: 'Automatización de procesos de negocio con Process Builder o Flows: Creación de procesos automatizados más complejos para áreas clave como ventas o servicio al cliente.' },
                { key: '40-horas-2', label: 'Customización avanzada de reportes y dashboards: Desarrollo de hasta 15 dashboards interactivos para monitoreo y KPIs.' },
                { key: '40-horas-3', label: 'Integración de plataformas externas a través de API sencillas: Integración de plataformas como Slack, Google Drive o herramientas de CRM adicionales.' },
                { key: '40-horas-4', label: 'Configuración de reglas de validación y lógica de negocio: Implementación de reglas para asegurar la calidad de los datos.' },
                { key: '40-horas-5', label: 'Optimización de formularios web-to-lead o web-to-case: Mejoras en la captura de leads desde el sitio web.' },
              ]
            },
          ]
        }
      ]
    }
  ];

  return details.find(s => s.service == service);
};
